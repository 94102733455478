'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.controller:MundoAdminCtrl

 # @description

###
class MundoAdminCtrl
  ### @ngInject ###
  constructor:(
    $scope,
    $log,
    _
  ) ->

    @navigationItems = [
        state: 'adminhome'
        icon: 'home'
        label: 'app.admin.home'
      ,
        state: 'users'
        icon: 'people'
        label: 'app.admin.users'
      ,
        state: 'dispatch'
        icon: 'map'
        label: 'app.admin.dispatch'
      ,
        state: 'incidents'
        icon: 'warning'
        label: 'app.admin.incidents'
      ,
        state: 'assets'
        icon: 'account_box'
        label: 'app.admin.assets'
      ,
        state: 'contacts'
        icon: 'perm_identity'
        label: 'app.admin.contacts'
      ,
        state: 'locations'
        icon: 'location_on'
        label: 'app.admin.locations'
      ,
        state: 'devices'
        icon: 'devices'
        label: 'app.admin.devices'
      ,
        state: 'units'
        icon: 'extension'
        label: 'app.admin.units'
      ,
        state: 'tasks'
        icon: 'extension'
        label: 'app.admin.tasks'
      ,
        state: 'notifications'
        icon: 'message'
        label: 'app.admin.notifications'
        showExpression: '\
hasPermission("manage all MundoMosaNotificationBundle:Notification entities") ||\
hasPermission("manage tenant MundoMosaNotificationBundle:Notification entities")'
    ]


angular
  .module('mundoAdmin')
  .controller 'MundoAdminCtrl', MundoAdminCtrl
